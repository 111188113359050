<template>
    <div class="s_flex_bian_c s_pa20 mb25">
      <div>
        <p class="mr15">经度：{{shopInfo.lng||'-'}}</p>
        <p class="mr15">纬度：{{shopInfo.lat|| '-'}}</p>
      </div>
      <van-button type="primary" @click="confirm" size="small"  >确认</van-button>
    </div>
  <div id="atlas"></div>
</template>
<script>
import { onMounted, reactive, toRefs } from "vue";
import { TMap } from "./map.js";
export default {
  name: "Txmap",
  props: {
    province: Object,
    address: String,
    positionValue: String,
    detail:String,
    lat: Number || String,
    lng: Number || String,
  },
  setup(props, { emit }) {
    const state = reactive({
      markersArray: [],
      map: null,
      getAddress: null,
      getAddCode: null,
      addressKeyword: "",
      centetLng: 113.36199,
      centetLat: 23.12463,
      shopInfo: {
        lng: "",
        lat: "",
      },
    });
    const mapTX = () => {
      TMap().then((qq) => {
        var center = new qq.maps.LatLng(state.centetLat, state.centetLng);
        var map = new qq.maps.Map(document.getElementById("atlas"), {
          center: center,
          zoom: 13,
        });
        var marker = new qq.maps.Marker({
          map: map,
          position: center,
        });
        state.markersArray.push(marker);
        //绑定单击事件添加参数
        qq.maps.event.addListener(map, "click", function (event) {
          if (state.markersArray.length) {
            for (var i in state.markersArray) {
              state.markersArray[i].setMap(null);
            }
          }
          state.shopInfo.lng = event.latLng.lng;
          state.shopInfo.lat = event.latLng.lat;
          var marker = new qq.maps.Marker({
            map: map,
            position: event.latLng,
          });
          state.markersArray.push(marker);
          getAddressCode(qq);
        });
        //调用地址显示地图位置并设置地址
        state.getAddress = new qq.maps.Geocoder({
          complete: function (result) {
            if (state.markersArray.length) {
              for (var i in state.markersArray) {
                state.markersArray[i].setMap(null);
              }
            }
            map.setCenter(result.detail.location);
            state.shopInfo.lng = result.detail.location.lng;
            state.shopInfo.lat = result.detail.location.lat;
            var marker = new qq.maps.Marker({
              map: map,
              position: result.detail.location,
            });
            state.markersArray.push(marker);
          },
        });
        //通过坐标来显示地图地址
        state.getAddCode = new qq.maps.Geocoder({
          complete: function (result) {
            state.addressKeyword = result.detail.address;
          },
        });
      });
    };
    //通过地址获得位置
    const getAddressKeyword = () => {
      //通过getLocation();方法获取位置信息值
      var addressKeyword = state.addressKeyword;
      state.getAddress.getLocation(addressKeyword);
    };
    // 通过坐标获得地址
    const getAddressCode = (qq) => {
      var lat = parseFloat(state.shopInfo.lat);
      var lng = parseFloat(state.shopInfo.lng);
      var latLng = new qq.maps.LatLng(lat, lng);
      //调用获取位置方法
      state.getAddCode.getAddress(latLng);
    };
    const confirm = () => {
      emit("confirm", state.shopInfo);
    };
    onMounted(() => {
      if (props.lat && props.lng) {
        state.centetLat = Number(props.lat);
        state.centetLng = Number(props.lng);
      }
      state.addressKeyword = props.positionValue + props.detail;
      mapTX();
      setTimeout(() => {
          getAddressKeyword()
      }, 500);
    });
    return {
      ...toRefs(state),
      mapTX,
      getAddressKeyword,
      getAddressCode,
      confirm,
    };
  },
};
</script>

<style lang="less">
#atlas {
  height: 800px;
  width: 100%;
}
.s_flex_ali {
  display: flex;
  align-items: center;
}
.s_flex_bian_c{
    display: flex;
  align-items: center;
  justify-content: space-between;
}
.s_pa20 {
  padding: 20px;
}
.mb25 {
  margin-bottom: 25px;
}
</style>