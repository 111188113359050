export function TMap() {
  return new Promise(function(resolve, reject) {
    window.init = function() {
      resolve(window.qq)
    }
    var script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = 'https://map.qq.com/api/js?v=2.exp&callback=init&key=POABZ-HN5WP-GMVDQ-VYP6K-MC3YE-J4FZY'
    script.onerror = reject
    document.head.appendChild(script)
  })
}