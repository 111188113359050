<template>
  <van-form @submit="onSubmit" label-width="7em">
    <van-field v-model="form.storename" name="storename" label="门店名称" placeholder="门店名称" :rules="[{ required: true, message: '请填写门店名称' }]" required />
    <van-field v-model="form.mobile" name="mobile" label="手机号" placeholder="手机号" :rules="[{ required: true, message: '请填写手机号' }]" required />
    <van-field v-model="form.password" name="password" label="密码" placeholder="密码" :rules="[{ required: true, message: '请填写密码' }]" required />
    <van-field v-model="form.person" name="person" label="门店负责人" placeholder="门店负责人" :rules="[{ required: true, message: '请填写门店负责人' }]" required />

    <van-field v-model="fieldValue" label="门店地址" is-link readonly placeholder="门店地址" @click="show = true" :rules="[{ required: true, message: '请选择门店地址' }]" required />
    <van-field v-model="form.detail" name="detail" label="详情地址" placeholder="详情地址" :rules="[{ required: true, message: '请选择地址' }]" required />
    <van-field v-model="form.gps" v-if="form.detail" label="门店定位" is-link readonly placeholder="门店定位" @click="show1 = true"  />
    <van-field label="门店图片" >
      <template #input>
        <van-uploader v-model="uploader" :max-count="1" :after-read="afterRead2">
        </van-uploader>
      </template>
    </van-field>
    <div style="margin: 16px;">
      <van-button round block type="primary" native-type="submit">提交</van-button>
    </div>
  </van-form>
  <van-popup v-model:show="show" round position="bottom" key='1'>
    <van-cascader :field-names="fieldNames" v-model="cascaderValue" title="请选择所在地区" :options="options" @close="show = false" @change="onChange" @finish="onFinish" />
  </van-popup>
 <van-popup v-model:show="show1" round position="bottom" key='1' >
    <div v-if="show1">
        <Tmap  :positionValue="fieldValue" :detail="form.detail" v-on:confirm="confirmMap"  />
    </div>
  </van-popup>
</template>
<script>
import {
  getProvince,
  getCity,
  getDistrict,
  doupload,
  storeReg,
} from "@/api/index";
import { onMounted, reactive, toRefs } from "vue";
import Tmap from '@/components/map/map.vue'
import { Toast } from "vant";
import { useRouter } from "vue-router";
const fieldNames = {
  text: "text",
  value: "region_id",
};
export default {
  components:{Tmap},
  setup() {
    const router = useRouter();
    const state = reactive({
      config: {},
      cascaderValue: "",
      show: false,
      show1: false,
      uploader: [],
      options: [
        {
          text: "浙江省",
          value: "330000",
          children: [],
        },
      ],
      options1: [
        {
          text: "浙江省",
          value: "330000",
          children: [],
        },
      ],
      fieldValue: "",
      form: {
        gps: "",
        mobile: "",
        password: "",
        img_url: "",
        person:'',
        storename: "",
        country: 1,
        province: "",
        city: "",
        detail: "",
      },
    });
    const initData = async () => {
      let options = await getProvince()
        .then((res) => res.data)
        .catch((error) => error);
      state.options = options.msg;
      state.options1 = options.msg;
    };
    onMounted(() => {
      initData();
    });
    const onSubmit = async () => {
      let result = await storeReg(state.form)
        .then((res) => res.data)
        .catch((error) => error);
      if (result.code == 200) {
        Toast.success(result.msg);
        router.push({ path: "/result" });
      } else {
        Toast.fail(result.msg);
      }
    };
    const afterRead2 = async (e) => {
      let result = await doupload({ image: e.content })
        .then((res) => res.data)
        .catch((error) => error);
      state.form.img_url = result.url;
    };
    const onChange = async function ({ selectedOptions, value, tabIndex }) {
      state.form.province = "";
      state.form.city = "";
      state.form.area = "";
      if (tabIndex == 0) {
        var index = state.options.findIndex((v) => v.region_id == value);
        let result = await getCity({ parent_id: value })
          .then((res) => res.data)
          .catch((error) => error);
        state.options[index].children = result.msg;
      } else if (tabIndex == 1) {
        state.form.city = value;
        var pIndex = state.options.findIndex(
          (v) => v.region_id == selectedOptions[0].region_id
        );
        var p = state.options.find(
          (v) => v.region_id == selectedOptions[0].region_id
        );
        var cIndex = p.children.findIndex((v) => v.region_id == value);
        let result = await getDistrict({ parent_id: value })
          .then((res) => res.data)
          .catch((error) => error);
        state.options[pIndex].children[cIndex].children = result.msg;
      }
    };
    const onFinish = function ({ selectedOptions }) {
      if (selectedOptions.length == 3) {
        state.show = false;
        state.form.province = selectedOptions[0].region_id;
        state.form.city = selectedOptions[1].region_id;
        state.form.area = selectedOptions[2].region_id;
      }
      state.fieldValue = selectedOptions.map((option) => option.text).join("/");
    };
    const confirmMap=(e)=>{
        state.form.gps=e.lat+','+e.lng
        state.show1=false
    }
    return {
      onSubmit,
      afterRead2,
      ...toRefs(state),
      onChange,
      onFinish,
      fieldNames,
      confirmMap
    };
  },
};
</script>
<style lang="less" scoped>
.uploader_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  font-size: 14px;
  color: #666;
  img {
    width: 80px;
    height: 80px;
  }
}
</style>